export const columns = [
	{
		prop: 'modelName',
		label: '车型名称',
		width: '280px',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '120px',
	},
	{
		prop: 'type',
		label: '保险类型',
		width: '120px',
	},
	{
		prop: 'startDate',
		label: '保险开始日期',
		width: '120px',
	},
	{
		prop: 'endDate',
		label: '保险结束日期',
		width: '120px',
	},
	{
		prop: 'annexesArr',
		label: '保单文件',
		width: '160px',
	},
	{
		prop: 'createTime',
		label: '保险操作时间',
		width: '180px',
	},
	{
		prop: 'createUserName',
		label: '保险操作人',
		width: '120px',
	},
	{
		prop: 'carNo',
		label: '自编号',
		width: '120px',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '120px',
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		width: '150px',
	},
	{
		prop: 'operation',
		label: '操作',
		fixed: 'right',
		width: '120px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			width: 358,
		},
		{
			placeholder: '请选择保险结束日期',
			label: '保险结束日期',
			attr: 'endDate',
			type: 'date',
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyId',
			type: 'multi-select',
			width: 320,
			options: [],
		},
		{
			placeholder: '请选择司服伙伴',
			label: '司服伙伴',
			attr: 'driverSupportId',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择保险类型',
			label: '保险类型',
			attr: 'typeList',
			type: 'multi-select',
			options: [
				{ code: 10, name: '交强险' },
				{ code: 20, name: '商业险' },
			],
		},
	],
}
